import { Component, OnInit } from '@angular/core';
import {Services} from '../services/services';
import {IImage} from 'ng-simple-slideshow';


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  public slideshowArray: Array<Array<IImage>>;

  constructor(public services: Services) {
    this.services.getIImages().then(data => {
      this.slideshowArray = data;
    });
  }

  ngOnInit() {
  }
}
