<div class="listContainer">
  <iframe id="inlineFrameExample"
          title="Inline Frame Example"
          width="80%"
          height="800"
          src="https://www.autoscout24.ch/fr/ip/belle-croix-cars-1680-romont-fr?accountid=1852197">
  </iframe>
</div>


