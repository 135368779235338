
<base href="/">
<div class="header">
<nav class="navbar navbar-expand-lg">
  <a class="navbar-brand" routerLink="/" routerLinkActive="active"><img width="200px" src="assets/img/logo.png" /></a>
  <a class="navbar-brand " style="color: white;" routerLink="/car-list" routerLinkActive="active">Voitures</a>
  <!-- <a class="navbar-brand " style="color: white;" routerLink="/suspension-air" routerLinkActive="active">Suspensions à air</a>
   <a class="navbar-brand "routerLink="/revo-tools" routerLinkActive="active"><img width="200px" src="assets/img/revoLogo.png" alt="revo" /></a>
   <a class="navbar-brand "routerLink="/swheels" routerLinkActive="active"><img width="200px" src="assets/img/swheelsLogo.png" alt="SWHEELS"/></a>
   <a class="navbar-brand "routerLink="/stop-and-go" routerLinkActive="active"><img width="200px" src="assets/img/stopgoLogo.PNG" alt="Stop & Go"/></a>
  <a class="navbar-brand " style="color: white;" routerLink="/enterprise-details" routerLinkActive="active">Qui Sommes-nous</a> --></nav>
</div>

<router-outlet></router-outlet>
<div class="footer">Site en construction</div>
