import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-swheels',
  templateUrl: './swheels.component.html',
  styleUrls: ['./swheels.component.scss']
})
export class SwheelsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
