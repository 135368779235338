import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-revo-tools',
  templateUrl: './revo-tools.component.html',
  styleUrls: ['./revo-tools.component.scss']
})
export class RevoToolsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
