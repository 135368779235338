import {Injectable, NgModule} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IImage} from 'ng-simple-slideshow';


@Injectable()
export class Services {
  public static BASEURL = 'https://carsandcafe.ch:443/rest/public'; // PROD
  constructor(private httpClient: HttpClient) {

  }
  public getIImages(): Promise<Array<Array<IImage>>> {
    return new Promise(resolve => {
      this.httpClient.get(Services.BASEURL + '/getSlideshow').subscribe(data => {
        resolve(data as Array<Array<IImage>>);
      });
    });
  }


}
