import {Component} from '@angular/core';
import {Services} from './services/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {


  constructor(public services: Services) {
  }

  title = 'garage';
}
