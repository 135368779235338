import {Component, Inject, OnInit} from '@angular/core';
import {Services} from '../services/services';
import {Router} from '@angular/router';


@Component({
  selector: 'app-car-list',
  templateUrl: './car-list.component.html',
  styleUrls: ['./car-list.component.scss']
})


export class CarListComponent implements OnInit {


  constructor(public services: Services, private router: Router) {

  }

  ngOnInit() {
  }



}
