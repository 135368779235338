
<div class="listContainer">
  <div *ngFor="let slide of slideshowArray">
    <div class="slideshow">
    <slideshow
               [imageUrls]= "slide.slideshow"
               [autoPlay]="true" [showArrows]="false"  [autoPlayInterval]="5000"
    ></slideshow>
    </div>
  </div>
</div>

<!-- Booking Widget Start --> <script> window.shoreBookingSettings = { themeColor: '#0099ff', textColor: '#ffffff', text: 'Réservez maintenant', company: 'belle-croix-cars', locale: 'fr', position: 'left', selectLocation: false, }; </script> <script src="https://booking.localsearch.ch//widget/booking.js"></script> <!-- Booking Widget End -->


<div class="case">
 <!-- <div class="contentCase">
    <img class="banner" src="assets/img/logo.png"/>
    <p> Le site est actuellement en construction</p>
  </div> -->
  <div class="flexContainer">
    <div class="contentCase">
      <h1 class="contentTitle"> Horaires </h1>
      <div class="flexContainer">
        <div class="textContainer">
          <h2>Vente</h2>
          <h3>Lundi-Jeudi</h3>
          <p>9h00 - 12h00</p>
          <p>13h00 - 18h00</p>
          <h3>Vendredi</h3>
          <p>9h00 - 12h00</p>
          <p>13h00 - 17h00</p>
          <h3>Samedi</h3>
          <p>9h00 - 13h00</p>
        </div>
        <div class="separator"></div>
        <div class="textContainer">
          <h2>Atelier</h2>
          <h3>Lundi-Jeudi</h3>
          <p>7h30 - 12h00</p>
          <p>13h30 - 17h30</p>
          <h3>Vendredi</h3>
          <p>7h30 - 12h00</p>
          <p>13h30 - 17h00</p>
        </div>
      </div>
    </div>
    <div class="contentCase">
      <h1 class="contentTitle"> Où nous trouver </h1>
      <h1 class="title"> Contact </h1>
      <div class="flexContainer">
        <p><img src="assets/img/icons/phone-call-icon.png" width="20px" class="icon">026 652 89 89 </p>
        <p><img src="assets/img/icons/mail-icon.png" width="20px" class="icon">info@bellecroixcars.ch</p>
      </div>
      <h1 class="title"> Adresse </h1>
      <iframe width='50%' height='50%' id='mapcanvas'
              src='https://maps.google.com/maps?q=Route%20de%20la%20belle-croix%2028,%20Romont&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=&amp;output=embed'
              frameborder='0' scrolling='no' marginheight='0' marginwidth='0'>
        <div style='overflow:hidden;'>
          <div id='gmap_canvas' style='height:100%;width:100%;'>
            <div class="zxos8_gm"><a
              href="https://www.twinstiarasandtantrums.com/2019/01/i-really-hate-my-car/">here</a></div>
          </div>
        </div>
        <div>
          <small>Powered by <a href="https://www.embedgooglemap.co.uk">Embed Google Map</a>
          </small>
        </div>
      </iframe>
    </div>
  </div>
</div>
