import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stop-and-go',
  templateUrl: './stop-and-go.component.html',
  styleUrls: ['./stop-and-go.component.scss']
})
export class StopAndGoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
