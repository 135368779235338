import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EnterpriseDetailsComponent } from './enterprise-details/enterprise-details.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { CarListComponent } from './car-list/car-list.component';
import {Services} from './services/services';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {SlideshowModule} from 'ng-simple-slideshow';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import { SuspensionAirComponent } from './suspension-air/suspension-air.component';
import { StopAndGoComponent } from './stop-and-go/stop-and-go.component';
import { RevoToolsComponent } from './revo-tools/revo-tools.component';
import { SwheelsComponent } from './swheels/swheels.component';

@NgModule({
  declarations: [
    AppComponent,
    EnterpriseDetailsComponent,
    WelcomeComponent,
    CarListComponent,
    SuspensionAirComponent,
    StopAndGoComponent,
    RevoToolsComponent,
    SwheelsComponent
  ],
  imports: [
    SlideshowModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NoopAnimationsModule,
    MatSelectModule,
    MatCardModule,
    MatGridListModule
  ],
  providers: [
    HttpClient,
    Services
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
