<p>
  enterprise-services works not!
</p>

<div class="listContainer">
  <div class="personContainer">
    <img width="300px" height="200px" class="personPhoto" src="https://img.etimg.com/thumb/msid-58517562,width-643,imgsize-183605,resizemode-4/at-4mn-justin-biebers-mumbai-concert-will-be-the-most-expensive-gig-in-india-ever.jpg">
    <h2>Claudio Jofre</h2>
    <h3>Directeur</h3>
    <p><img src="./assets/img/icons/phone-call-icon.png" width="20px" class="icon">+41 79 123 45 56</p>
    <p><img src ="./assets/img/icons/mail-icon.png" width="20px" class="icon" >jofre.claudio@carsandcafe.ch </p>
  </div>

  <div class="personContainer">
    <img width="300px" height="200px" class="personPhoto" src="http://celebrityinsider.org/wp-content/uploads/2019/01/Brad-Pitt-Has-Not-Been-Seen-With-His-Children-In-Public-In-Almost-3-Years.jpg">
    <h2>Peter Vogt</h2>
    <h3> Membre du conseil d'administration</h3>
    <p><img src="./assets/img/icons/phone-call-icon.png" width="20px" class="icon">+41 79 123 45 56</p>
    <p><img src ="./assets/img/icons/mail-icon.png" width="20px" class="icon" >peter.vogt@carsandcafe.ch </p>
  </div>

  <div class="personContainer">
    <img width="300px" height="200px" class="personPhoto" src="https://channel-korea.com/wp-content/uploads/2018/07/taeyeon.jpg">
    <h2>Isabelle Vogt</h2>
    <h3>Membre du conseil d'administration</h3>
    <p><img src="./assets/img/icons/phone-call-icon.png" width="20px" class="icon">+41 79 123 45 56</p>
    <p><img src ="./assets/img/icons/mail-icon.png" width="20px" class="icon" >isabelle.vogt@carsandcafe.ch </p>
  </div>

  <div class="personContainer">
    <img width="300px" height="200px" class="personPhoto" src="http://image.noelshack.com/fichiers/2013/48/1385496548-tete-de-bite.jpg">
    <h2>Joel Vogt</h2>
    <h3>Développeur Web</h3>
    <!--  <p><img src="./assets/img/phone-call-icon.png" width="20px" class="icon">+41 79 123 45 56</p> -->
    <p><img src ="./assets/img/icons/mail-icon.png" width="20px" class="icon" >joel.vogt@carsandcafe.ch </p>
  </div>


</div>
