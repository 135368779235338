import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-suspension-air',
  templateUrl: './suspension-air.component.html',
  styleUrls: ['./suspension-air.component.scss']
})
export class SuspensionAirComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
