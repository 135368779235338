import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {WelcomeComponent} from './welcome/welcome.component';
import {CarListComponent} from './car-list/car-list.component';
import {SuspensionAirComponent} from "./suspension-air/suspension-air.component";
import {StopAndGoComponent} from "./stop-and-go/stop-and-go.component";
import {RevoToolsComponent} from "./revo-tools/revo-tools.component";
import {SwheelsComponent} from "./swheels/swheels.component";

const routes: Routes = [
  {path: '', component: WelcomeComponent},
 // {path: 'enterprise-details', component: EnterpriseDetailsComponent},
  {path: 'car-list', component: CarListComponent},
  {path: 'suspension-air', component: SuspensionAirComponent},
  {path: 'stop-and-go', component: StopAndGoComponent},
  {path: 'revo-tools', component: RevoToolsComponent},
  {path: 'swheels', component: SwheelsComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
