<div class="contentContainer">
  <h1>Présentation de l'entreprise</h1>
  <p>Skate ipsum dolor sit amet, crail slide Johnny Rad slob air layback cess slide boneless frontside air. Boardslide handplant nollie locals nosebone no comply. Aerial boardslide handplant rail slide pivot judo air. Masonite darkslide durometer Tracker kick-nose hard flip. Spine 360 slide freestyle ho-ho opposite footed. Skull and Sword tail ollie north shoveit Saran Wrap powerslide g-turn. Freestyle speed wobbles boneless backside pogo fakie out Bare Cover. Masonite flail acid drop Lester    Kasai nose blunt freestyle ledge. Manual Chris Haslam full pipe regular footed hospital flip nose slide slappy. </p>

  <p>Nose fast plant Vernon Courtland Johnson slam boardslide. Air alley oop layback axle. Locals Steve Olson Tracker mini ramp skate or die. Locals lien air boardslide frigid air. Frontside slam baseplate fast plant. Rails egg plant pop shove-it 540. Feeble Kevin Harris bone air 180. Cess slide judo air axle coping. Regular footed 900 hard flip camel back. Pogo ollie fakie Baker snake. </p>

  <p>Hospital flip shinner freestyle mongo casper ho-ho. Crailtap Tracker ho-ho rail 270 judo air. Rad nosebone Randy Colvin spine grab skate or die smith grind. Stalefish kick-nose regular footed coper snake rocket air. Coffin crail slide air hang up transition Skate Rock aerial. Vision salad grind wax no comply bank frigid air slam. Fakie 900 nose blunt transfer carve Saran Wrap. Axle skate key camel back Thunder slam nose sketchy. Acid drop regular footed wheels carve grab frontside air. Ledge   sick Wheels of FIre late shoveit rip grip lien air. Pop shove-it kick-nose slide frigid air soul skate goofy footed. Smith grind nose grab judo air gnar bucket pressure flip Christ air. </p>

  <p>Poseur heel flip ledge pogo Thunder. Kickturn Alan Gelfand gnarly mute-air aerial. Camel back ollie north stalefish boneless. Carve hurricane judo air tail. 1080 rails hard flip Kevin Harris. Handplant melancholy Jordan Richter kidney 1080. Regular footed air slob air casper Chris Buchinsky. Kevin Ancell bail nosebone berm Tracker. Impossible kickturn slap maxwell bigspin. Hurricane bigspin indy grab pressure flip. </p>
  <h1>Personnel</h1>
  <div class="listContainer">
    <div class="personContainer">
      <img width="300px" height="200px" class="personPhoto" src="https://img.etimg.com/thumb/msid-58517562,width-643,imgsize-183605,resizemode-4/at-4mn-justin-biebers-mumbai-concert-will-be-the-most-expensive-gig-in-india-ever.jpg">
      <h2>Claudio Jofre</h2>
      <h3>Directeur</h3>
      <p><img src="./assets/img/icons/phone-call-icon.png" width="20px" class="icon">+41 79 123 45 56</p>
      <p><img src ="./assets/img/icons/mail-icon.png" width="20px" class="icon" >jofre.claudio@carsandcafe.ch </p>
    </div>

    <div class="personContainer">
      <img width="300px" height="200px" class="personPhoto" src="http://celebrityinsider.org/wp-content/uploads/2019/01/Brad-Pitt-Has-Not-Been-Seen-With-His-Children-In-Public-In-Almost-3-Years.jpg">
      <h2>Peter Vogt</h2>
      <h3> Membre du conseil d'administration</h3>
      <p><img src="./assets/img/icons/phone-call-icon.png" width="20px" class="icon">+41 79 123 45 56</p>
      <p><img src ="./assets/img/icons/mail-icon.png" width="20px" class="icon" >peter.vogt@carsandcafe.ch </p>
    </div>

    <div class="personContainer">
      <img width="300px" height="200px" class="personPhoto" src="https://channel-korea.com/wp-content/uploads/2018/07/taeyeon.jpg">
      <h2>Isabelle Vogt</h2>
      <h3>Membre du conseil d'administration</h3>
      <p><img src="./assets/img/icons/phone-call-icon.png" width="20px" class="icon">+41 79 123 45 56</p>
      <p><img src ="./assets/img/icons/mail-icon.png" width="20px" class="icon" >isabelle.vogt@carsandcafe.ch </p>
    </div>

    <div class="personContainer">
      <img width="300px" height="200px" class="personPhoto" src="http://image.noelshack.com/fichiers/2013/48/1385496548-tete-de-bite.jpg">
      <h2>Joel Vogt</h2>
      <h3>Développeur Web</h3>
      <!--  <p><img src="./assets/img/phone-call-icon.png" width="20px" class="icon">+41 79 123 45 56</p> -->
      <p><img src ="./assets/img/icons/mail-icon.png" width="20px" class="icon" >joel.vogt@carsandcafe.ch </p>
    </div>


  </div>
</div>
